import { render, staticRenderFns } from "./FindInfoProduct.vue?vue&type=template&id=0b3d5484&scoped=true&"
import script from "./FindInfoProduct.vue?vue&type=script&lang=js&"
export * from "./FindInfoProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b3d5484",
  null
  
)

export default component.exports