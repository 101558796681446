<template>
  <div>
    <h1 class="my-4 mb-mb-5 d-md-inline-block mr-md-4">Создание и запуск инфопродуктов</h1>
    <b-button
      :to="{ name: 'create-infoproduct' }"
      variant="yellow"
      class="d-md-inline-block w-100 w-md-auto ph-42 mb-4"
      >Создайте мне инфопродукт!</b-button
    >
    <div class="card-wrapper" v-if="infoproducts">
      <div
        v-for="infoproduct in infoproducts"
        :key="infoproduct.id"
        style="max-width: 450px; height: fit-content; min-height: 670px"
        class="hovering__pad p-4"
      >
        <info-product
          @toggle-hidden="toggleHidden"
          @become-producer="becomeProducer"
          :infoproduct="infoproduct"
        ></info-product>
      </div>
    </div>
    <loader v-else></loader>
  </div>
</template>
<script>
import producing from '@main/api/producing';
import { mapState } from 'vuex';
import Loader from '../Loader.vue';
import InfoProduct from './InfoProduct.vue';

const defField = { thClass: 'mb-5' };

export default {
  components: { Loader, InfoProduct },
  data: () => ({
    infoproducts: null,
  }),
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.infoProducts = null;
      const infoproducts = await producing.infoProducts.list();
      this.infoproducts = infoproducts.results;
    },
    async toggleHidden(id, remove) {
      await producing.infoProducts.toggleHidden(id, remove);
      await this.loadData();
    },
    becomeProducer(id) {
      this.$router.push({ name: 'reply-to-infoproduct', params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
